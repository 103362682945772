
import { defineComponent } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { Form, Field, ErrorMessage } from "vee-validate";
import { VueCookieNext } from "vue-cookie-next";

export default defineComponent({
  name: "new-entity-target",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      target: {
        entity_info_id: "",
        tranche_id: "",
        target_year_id: "",
        target_year_from: "",
        target_year_to: "",
        target_trainee_number: "",
        active_status: "",
        user_id: "",
        role_id: "",
      },
      fromYear: [] as any,
      toYear: [] as any,
      tranche: [],
      association: [],
      targetYears: [],
      loading: false,
    };
  },
  async created() {
    await this.getTranche();
    await this.associationList();
    await this.getTargetYear();
    await this.getYear();
  },
  methods: {
    async associationList() {
      let entity_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      await ApiService.get("entity/list?entity_id=" + entity_id)
        .then((response) => {
          this.association = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranche() {
      await ApiService.get("configurations/tranche/list")
        .then((response) => {
          this.tranche = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTargetYear() {
      await ApiService.get("configurations/target_year/list")
        .then((response) => {
          this.targetYears = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getYear() {
      let currentYear = new Date().getFullYear();
      let CurrentYearTo = new Date().getFullYear();
      for (let i = 20; i > 0; i--) {
        this.fromYear.push(currentYear);
        console.log(this.fromYear);
        currentYear--;
      }
      for (let i = 0; i < 20; i++) {
        this.toYear.push(CurrentYearTo);
        CurrentYearTo++;
      }
    },
    async formSubmit() {
      let user_id = VueCookieNext.getCookie('_seip_user');
      let role_id = VueCookieNext.getCookie('_seip_role_id');
      this.target.user_id = user_id.id;
      this.target.role_id = role_id;
      let data = {
        target: this.target,
      };
      this.loading = true;
      await ApiService.post("entity/target/save", data)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.target = {
                entity_info_id: "",
                tranche_id: "",
                target_year_id: "",
                target_year_from: "",
                target_year_to: "",
                target_trainee_number: "",
                active_status: "",
                user_id: "",
                role_id: "",
              };
            });
            
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
